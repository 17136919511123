@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Rubik', sans-serif, 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  :root {
    --background: 0, 0%, 97%, 1;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 60, 3%, 38%, 1;
    --primary-foreground: 60, 3%, 38%, 1;

    --secondary: 269, 45%, 56%;
    --secondary-foreground: 173, 47%, 93%, 1;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 269, 89%, 94%, 1;
    --accent-foreground: 269, 45%, 56%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 60, 3%, 38%, 70%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --text-color: 0, 0%, 20%, 1;
  }

  .dark {
    --background: 0, 0%, 11%, 1;
    --foreground: 0, 0%, 14%, 1;

    --card: 0, 0%, 82%, 10%;
    --card-foreground: 0, 0%, 82%, 1;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 60, 3%, 38%, 1;
    --primary-foreground: 60, 3%, 38%, 1;

    /* --secondary: 172, 90%, 35%, 1; */
    --secondary: 269, 45%, 56%;

    --secondary-foreground: 0, 0%, 14%, 1;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --ring: 240 4.9% 83.9%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.bg-authimage {
  background-image: url('../public/svg/auth-modal.svg');
}
.icon-hovered {
  color: hsl(var(--secondary));
}

.textBox-shadow {
  box-shadow: 0px 0px 4px 0px hsl(var(--secondary));
}

ol {
  list-style-type: decimal;
  margin: 2px;
  padding: 10px;
}

li > a {
  color: revert;
  text-decoration: revert;
}

pre {
  overflow-x: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  /* padding: 1rem; */
  margin: 1rem;
  background-repeat: initial;
  font-family: monospace;
  background: rgb(59, 59, 59);
  border-radius: calc(var(--radius) - 2px);
  /* color: white; */
}

.truncated-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
